<template>
  <div class="create-integration-page">
    <BackTitle confirm-click @click="goBack"> Create integration </BackTitle>
    <IntegrationForm
      v-model="integration"
      :isSubmitting="isSubmitting"
      @submit="save"
      @remove="remove"
    />
  </div>
</template>

<script>
import BackTitle from "@/components/common/BackTitle.vue";
import IntegrationForm from "@/components/integrations/IntegrationForm";
import { mapActions } from "vuex";

export default {
  name: "CreateIntegrationPage",
  components: { BackTitle, IntegrationForm },
  data() {
    return {
      integration: {
        isActive: true,
        params: {
          url: "",
          eventTypes: [],
        },
      },
      isSubmitting: false,
    };
  },
  computed: {},

  methods: {
    ...mapActions({
      createIntegration: "integrations/createIntegration",
    }),
    async save(data) {
      try {
        this.isSubmitting = true;
        await this.createIntegration(data);
        this.goBack();
      } finally {
        this.isSubmitting = false;
      }
    },
    remove() {},
    goBack() {
      this.$router.push({
        name: "Integrations",
      });
    },
  },
};
</script>

<style scoped lang="scss">
.create-integration-page {
  display: flex;
  flex-direction: column;
  gap: 40px;
  height: 100%;
}
</style>
